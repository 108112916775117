import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "../../global1.css";
import DefaultLogo from "../../assests/images/DefaultLogo.png";
import DefaultBack from "../../assests/images/background-img.png";
import { Backdrop, Link } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DOMPurify from "dompurify";
import LanguageChange from "../LanguageChange/LanguageChange";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FloatingLabelDatePicker from "../DateOfBirth/FloatingDatePicker";


//****** LOADER  */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: '100%',
    },
  })
);

 /******  CHANGING FAVICON DYNAMICALLY  */

 function getFaviconEl() {
  return document.getElementById('favicon');
}
function getAppTitle() {
  return document.getElementById('AppTitle');
}

function Updateprofile() {


  //********* INITIAL VARIABLES **************/
 
  const { REACT_APP_API_ENDPOINT, REACT_APP_API_ENDPOINT_SECURE } = process.env;
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [intialLoader, setIntialLoader] = useState(false);
  const [data4, setData4] = useState({});
  const [userData, setuserData] = useState({});
  const [signInRedirect, setSignInRedirect] = useState('');
  const [attributeConfigDetails,setAttributeConfigDetails] = useState();
  const [regionsObj, setRegionsObj] = useState()
  const inputRef = useRef({});
  const [formikErrors,setFormikErrors] = useState({})
  // const dispatch = useDispatch()
const  { executeRecaptcha }  = useGoogleReCaptcha()
//*****  FETCHING DATA FROM QUERY PARAMETERS *****/

const Data = useParams()
const id = DOMPurify.sanitize(Data.id);
const user_id = DOMPurify.sanitize(Data.user_id);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let token1 = DOMPurify.sanitize(query.get("token"));
  let social = DOMPurify.sanitize(query.get("social"));
  const LanguageCode = DOMPurify.sanitize(query.get("languageCode"));
  const[IsSocial, setIsSocial] = useState(false)
  const[socialLoginPhoneDisableField, setSocialLoginPhoneDisableField] = useState(false)

  //***** REDIRECT URL   ******** */

  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setSignInRedirect(res.data.signInRedirectURL);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);


  const [dropDownURL, setDropdownURL] = useState('')
  const userDetailsServiceCalled = useRef(false);
  useEffect( () => {
    if(attributeConfigDetails){
      attributeConfigDetails?.baseAttributes?.forEach( async(item) => {
        const currentUrl = item?.dropDownURL
        if(item.dropDownURL !== ''){
          await axios.get(`${item.dropDownURL}`)
           .then( res => { 
            if(currentUrl !== dropDownURL){
                setRegionsObj(res.data)
            }
          setDropdownURL(currentUrl);
           })
           .catch( err =>{
          console.log({err})
          })
        }
      })
    }
    //eslint-disable-next-line
  },[attributeConfigDetails])
  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res?.data);
        setIntialLoader(false);
        setAttributeConfigDetails(res?.data.attributeConfigDetails);
        if(res.data?.appBasicDetails?.enableGoogleCaptcha){
          captchaVisibility()
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);
  
  //***********  GETTING DYNAMIC USER DATA FROM OKTA */

  async function User_details() {
    setLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT_SECURE}get-dynamic-user-detail/${user_id}/${id}`, {
        headers: {
          Authorization: `Bearer ${token1}`,
        },
      })
      .then((res) => {
        setLoader(false);
        let Obj = {}
        Object.keys(res.data.profile).forEach((key) => {
          Obj={...Obj,[key]:res.data.profile[key]}
        }
        );
        if(social === "true"){
          setIsSocial(false)
           if(res.data?.profile?.social === "GOOGLE"){
             setIsSocial(true)
           }else if(res.data?.credentials?.provider?.type === 'SOCIAL'){
              setIsSocial(true)
           }else if(res.data?.profile?.source === 'Google'){
            setIsSocial(true)
           }
           else{
            setIsSocial(false)
           }
        }
        //eslint-disable-next-line
        setuserData(Obj);
        // setFlag(true)
      }).catch( err => {
        setLoader(false)
        
        if(err.response.data.errorCode === '401'){
           toast.error(err.response.data.errorMessage,{
            position: 'top-center'
           })
        }else if( err.response.data.messgae === "Unauthorised Access"){
          toast.error(err.response.data.messgae, {position:'top-center'})
        }
      });
  }

  //***** SOCIAL LOGIN ROUTE  */

  // useEffect(() => {
  //   if(signInRedirect && flag){
  //     let redirectroute = true
  //       if(social){
  //         Object.keys(userData).forEach( (e,i) => {
  //           if(userData[e]){
              
  //           }else{
  //             redirectroute =false
  //           }
  //         })

  //         if(redirectroute === true){
  //           window.location.href = signInRedirect;
  //         }
  //       }

  //   }
    
  //       //eslint-disable-next-line
  // },[signInRedirect,flag])

  useEffect(() => {
    let RegionsExcludedIds = ["824955038072832", "163142638239744", "915041708670976","379698395742208", "401020291121152", "363459124396032", "136971246632960", "410142331895808"]
    if(RegionsExcludedIds.includes(id)){
      User_details();
    }else if(attributeConfigDetails && regionsObj && Object.keys(regionsObj).length > 0 && !userDetailsServiceCalled.current){
      User_details();
      userDetailsServiceCalled.current = true;
    }  
    //eslint-disable-next-line
  }, [attributeConfigDetails,regionsObj]);



  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const Header_Color = data4.custom_properties?.headerColor 
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg? data4.backgroundImg[0]: DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;
  const AppTitle = data4.appBasicDetails?.appName;
  const PrivacyPolicy = data4?.appBasicDetails?.privacyPolicy;
  const TermsConditions = data4?.appBasicDetails?.termsAndConditions;


  //**************** DATE RESTRICTION ******* */

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - data4?.appBasicDetails?.minimumAge);



    //***** Getting Language Data FROM Redux *****

    const PageName = "lbl_update_profile";
    let langData;
    const LangData = useSelector((state) => state);
    const CodeLang = LangData?.LanguageData?.code; 
    const pageName = LangData?.LanguageData?.pageName;
    const defaultLang = LangData?.LanguageData?.LanguageData[0]?.code;
    if (defaultLang === "default" && LangData?.LanguageData?.LanguageData?.filter((item) => item.code === CodeLang && item.pageName === pageName).length === 0){
      langData = LangData?.LanguageData?.LanguageData?.filter((item) => item.code === "default")[0]?.data;
    } else {
      langData =LangData?.LanguageData.LanguageData.length > 0? LangData?.LanguageData?.LanguageData?.filter((item) => item.code === CodeLang && item.pageName === pageName)[0]?.data : {};
    }

    // useEffect(() => {
    //   dispatch(fetchLanguageData(LanguageCode, id, PageName));
    //   //eslint-disable-next-line
    // }, []);
 
    //*******   UPDATING THE USER DATA API */
  const updateProfile = async (userObj) => {
  if(userData?.primaryPhone){
    setLoader(true);
         axios
          .put(
            `${REACT_APP_API_ENDPOINT_SECURE}custom-update-user/${user_id}/${id}?isSocialLogin=${IsSocial}`,{
            ...userObj
            },
            {
              headers: {
                Authorization: `Bearer ${token1}`,
              },
            }
          )
          .then((res) => {
            setLoader(false);
            if (res.data.responseStatus === "SUCCESS") {
              toast.success(langData?.lbl_update_profile_success, {
                position: "top-center",
              });
              window.location.href = signInRedirect;
            }else{
              if(res.data.errorCode === 'invalid-captcha'){
                toast.error(res.data.messgae,{
                  position:"top-center"
              })
            }}
          }).catch( err => {
            setLoader(false);
                  
                  toast.error(err.response.data.errorCauses[0].errorSummary, {
                    position: "top-center",
                  });
          });
  }else{
    // setLoader(true)
    // await axios
    // .get(`${REACT_APP_API_ENDPOINT}check-mobile-no?mobileNo=${(data4?.appBasicDetails?.enableMobileCheck && social) ? userObj.primaryPhone  : undefined}`)
    //  .then(res => {
    //    setLoader(false);
    //    if (res.data && res.data.responseStatus === 'SUCCESS'){
    //       if(res.data.errorCode === 'invalid-mobile'){
    //        toast.error(langData?.lbl_update_profile_phone_exists, {
    //          position: 'top-center', width: 600
    //        });
    //       setuserData({
    //         ...userObj, primaryPhone:'', confirmPhone:''
    //       })
    //      }
    //    }else{
         setLoader(true);
          axios
           .put(
             `${REACT_APP_API_ENDPOINT_SECURE}custom-update-user/${user_id}/${id}?isSocialLogin=${IsSocial}`,{
             ...userObj
             },
             {
               headers: {
                 Authorization: `Bearer ${token1}`,
               },
             }
           )
           .then((res) => {
             setLoader(false);
             if (res.data.responseStatus === "SUCCESS") {
               toast.success(langData?.lbl_update_profile_success, {
                 position: "top-center",
               });
               window.location.href = signInRedirect;
             }else{
               if(res.data.errorCode === 'invalid-captcha'){
                 toast.error(res.data.messgae,{
                   position:"top-center"
               })
             }}
           }).catch( err => {
             setLoader(false);
                   
                   toast.error(err?.response?.data?.errorCauses[0]?.errorSummary, {
                     position: "top-center",
              
       });
     });
  }
   
    
  };


 //********* CALLING USER DATA API */
  
  const onSubmit = async (values, props) => {
    let Data = {  }
    let reCaptcha_token = ""
    if(data4.appBasicDetails?.enableGoogleCaptcha){
      if (!executeRecaptcha) {
       toast.error(langData?.lbl_update_profile_captcha_not_available, {
         position: 'top-center'
       })
       return;
     }
     reCaptcha_token = await executeRecaptcha("");
   }else{
    reCaptcha_token = ""
   }
    let newObject = {
      reCaptchaToken: reCaptcha_token
    }
    attributeConfigDetails.baseAttributes.filter(item => item.isSelect).forEach( item => {
      if(values[item.key]){
        Data = { ...Data, [item.key] : values[item.key] }
      }else{
        if(item.type === 'array'){
           Data = { ...Data, [item.key] : [] }
        }else if(item.type === 'boolean'){
          Data = {...Data, [item.key] : false}
        }
        else{
          Data = { ...Data, [item.key] : '' }
        }
      }
    })


    Object.keys(values).forEach( key => {
      const Obj = attributeConfigDetails?.baseAttributes.filter(item => item.isSelect).filter(item => item.key===key && item.mappedBy && item.mappedby !== '')
      if(Obj.length > 0){
        const mappedBy = Obj[0].mappedBy
        newObject = {
          ...newObject,
          [mappedBy] : Data[key]
        }
      }else{
        newObject = {
          ...newObject,
          [key] : Data[key]
        }
      }
    })
  
     updateProfile(newObject, props)
     setuserData(newObject) 
    setTimeout( () => {
      props.resetForm()
     },1000)
  }

  //**** REDIRECTIN BACK TO HOME PAGE OF APPLICATION */

  const BackHome = () => {
    window.location.href = signInRedirect ;
  };

  //**** GETTING FACTORS */

  async function Factors(){
    await axios
     .get(`${REACT_APP_API_ENDPOINT}factors/enroll-factor/${user_id}`)
     .then( (res) => {
         if(res.data.length > 0){
           const Factor = res.data.find( item => item.factorType = 'sms');
           let phoneNumber = Factor.profile?.phoneNumber?.slice(3)
          if( phoneNumber && phoneNumber?.length !== 10){
            phoneNumber = '0' + Factor.profile?.phoneNumber?.slice(3)
          }
           phoneNumber !== undefined &&
           setuserData( (prev)  => ({
              ...prev,
             phoneNumber: phoneNumber
           })) 
           
           setSocialLoginPhoneDisableField(true)
         }
     })
  }


  useEffect( () => {
    let excludeIds = ['163142638239744','915041708670976']
    if(excludeIds.includes(id)){
      IsSocial && Factors()
    }
     //eslint-disable-next-line
  },[IsSocial])
  


  //************************************ Dynamic Related Code start ***********************************/


  let initValue = null;
  let validityObject = {};
  attributeConfigDetails?.baseAttributes.filter(item => item.isSelect).forEach(item =>{
    if(item.key === "login"){

    }else if(item.type === 'array' && item?.enumList && item?.oneOf && item?.enumList.length>0 && item?.oneOf.length>0 ){
      initValue = {
        ...initValue,
        [item.key]: userData[item.key] || userData[item.mappedBy]
      }
    }else if(item.type === 'array'){
      initValue = {
        ...initValue,
        [item.key]: userData[item.key] || userData[item.mappedBy]
      }
    }else if(item?.enumList && item?.oneOf && item?.enumList.length>0 && item?.oneOf.length>0){
        initValue = {
          ...initValue,
          [item.key]: userData[item.key]  || userData[item.mappedBy]
          
        }
    }
    else if(item.type === 'boolean'){
      initValue = {
        ...initValue,
        [item.key]: userData[item.key] || userData[item.mappedBy] || false
      }
    }
    else{
      initValue = {
        ...initValue,
        [item.key]: userData[item.key] || userData[item.mappedBy]
      }
    }
    if(item.key === "login"){
    }else if(item.type === 'array' && item?.enumList && item?.oneOf && item?.enumList.length>0 && item?.oneOf.length>0){
      validityObject[item.key] = yup.array().of(yup.string().required(langData?.lbl_update_profile_field_required))
   }else if(item.type === 'array' && item.itemType === 'integer'){
     validityObject[item.key] = yup.string().test("required", langData?.lbl_update_profile_comma_integer, (value) => {
       if(value){
         let regex = /^[0-9]+(,?[0-9]+)*?$/g
         let Data = value.match(regex);
       if (Data) {
         return true;
       } else {
         return false;
       }
       }
     })

   }else if( item.type === "integer"){
       validityObject[item.key]= yup.string().test( "required", langData?.lbl_update_profile_only_number, (values) => {
         if(values){
           let regex = /^[0-9]*$/
           let Data = values.match(regex);
           if (Data) {
             return true;
           } else {
             return false;
           }

         }
       })
   }else if(item.key === "secondEmail"){
    validityObject[item.key] = yup.string().email(langData?.lbl_update_profile_not_valid_email)
   }
  //  else if(item.mappedBy === 'primaryPhone' && item.type === 'string' && item.required){
  //   validityObject[item.key] = yup
  //   .string()
  //   .required(getErrorMsg(item.key,1, item.title))
  //   .test('phone Validation', getErrorMsg(item.key,4, item.title),
  //    (value) => {
  //     if(value){
  //       let maxLength = item.maxLength
  //       const regex = new RegExp(`^[\\d]{${maxLength}}$`);
  //       let Data = value?.match(regex)
  //       if(Data){
  //         return true
  //       }else{
  //         return false
  //       }
  //     }
  //   }
  //   )
  // }else if(item.mappedBy === 'primaryPhone' && item.type === 'string'){
  //   validityObject[item.key] = yup
  //   .string()
  //   .test('phone Validation', getErrorMsg(item.key,4, item.title),
  //    (value) => {
  //     if(value){
  //       let maxLength = item.maxLength
  //       const regex = new RegExp(`^[\\d]{${maxLength}}$`);
  //       let Data = value?.match(regex)
  //       if(Data){
  //         return true
  //       }else{
  //         return false
  //       }
  //     }else{
  //       return true
  //     }
  //   }
  //   )
  // }
  else if(item.mappedBy === 'confirmPhone' && item.type === 'string'){
  validityObject[item.key] = yup
  .string()
  .required(getErrorMsg(item.key,1, item.title))
  .oneOf(
    [yup.ref('phoneNumber'), null], langData?.lbl_update_profile_phone_not_match
  )
}
// else if(item.mappedBy === 'socialId' && item.type === 'string'){
//   validityObject[item.key] = yup
//    .string()
//    .test('social Id Validation', getErrorMsg(item.key, 4, item.title),
//     (value) => {
//       if(value){
//         let regex = /[\d]{1,2}\.[\d]{3}\.[\d]{3}-[\dKk]{1}/
//         let Data = value?.match(regex)
//         if(Data){
//           return true
//         }else{
//           return false
//         }
//       }else{
//         return true
//       }
//     }
//    )
// }
else if(item.type === 'array' && item.itemType === 'string'){
     validityObject[item.key] = yup.string().test("required", langData?.lbl_update_profile_comma_string, (value) => {
       if(value){
         let regex = /^[a-zA-Z]+(,?[a-zA-Z]+)*?$/g
         let Data = value.match(regex);
       if (Data) {
         return true;
       } else {
         return false;
       }
       }
     })

   }else if(item.type === 'boolean'){
    validityObject[item.key] = yup
    .boolean()
    .test('required', getErrorMsg(item.key, 1, item.title), (value) => {
      if (item?.required) {
        if (value && value === true) {
          return true
        } else {
          return false;
        }
      }else{
        return true
      }
    })
  }else{
      validityObject[item.key] = 
      yup.string()
      .test("required",getErrorMsg(item.key,1,item.title),(value)=>{
       if(item?.required){
         if(value){

         }else{
           return false;
         }
       }
       return true
      })
      .test("minLength",getErrorMsg(item.minLength,2, item.title),(value)=>{
        if(value && item?.minLength &&  item.minLength > value.length){
          return false;
        }
        return true;
      })
      .test("maxLength",getErrorMsg(item.maxLength,3, item.title),(value)=>{
        if(value && item?.maxLength &&  item.maxLength < value.length){
          return false;
        }
        return true;
      })
    }
    if(item.regexValidator && item.required){
      validityObject[item.key] = yup
       .string()
       .required(getErrorMsg(item.key, 1, item.title))
       .test( item.key + 'validation', getErrorMsg(item.key, 4, item.title),
        (value) => {
          if(value){
            const regex = new RegExp(item.regexValidator);
            // let regex = /[\d]{1,2}\.[\d]{3}\.[\d]{3}-[\dKk]{1}/
            let Data = value?.match(regex)
            if(Data){
              return true
            }else{
              return false
            }
          }else{
            return true
          }
        }
       )
      
    }
    else if(item.regexValidator){
      validityObject[item.key] = yup
       .string()
       .test('validation', getErrorMsg(item.key, 4, item.title),
        (value) => {
          if(value){
            const regex = new RegExp(item.regexValidator);
            // let regex = /[\d]{1,2}\.[\d]{3}\.[\d]{3}-[\dKk]{1}/
            let Data = value?.match(regex)
            if(Data){
              return true
            }else{
              return false
            }
          }else{
            return true
          }
        }
       )
      
    }

  });
  const validitySchema = yup.object(validityObject);

 
  // TYPE =  1 For Required
  // TYPE =  2 for Minimum
  // Type = 3 for Maximum
  function getErrorMsg(key,type, title){
    if(type===1){
      return(
       langData &&
        langData["lbl_update_profile_required_argument"]?.replace(
          "{0}",
          langData["lbl_update_profile_"+key] ? langData["lbl_update_profile_"+key] : title)
       
      )
      
    }
    else if(type===2){
      return langData["lbl_update_profile_minimum_argument"] ? langData["lbl_update_profile_minimum_argument"]?.replace("{0}",key) : 'Atleast {0} characters are required'.replace("{0}",key);
    }
    else if(type===3){
      return langData["lbl_update_profile_maximum_argument"] ? langData["lbl_update_profile_maximum_argument"]?.replace("{0}",key) : 'Maximum {0} characters are allowed'.replace("{0}",key);
    }else if(type ===4){
       return( 
        langData &&
        langData['lbl_update_profile_not_valid_argument']?.replace(
          '{0}',
          langData['lbl_update_profile_' + key]
            ? langData['lbl_update_profile_' + key]
            : title
        )
       ) 
    }
  }
  //************************************ Dynamic Related Code End **************************************/


   //*********    FAVICON DYNAMIC CHANGE */

 const handlefavicon = () => {
  const favicon = getFaviconEl(); // Accessing favicon element
  if (logo) {
    favicon.href = logo;
  }
};
const handleAppTitle = () => {
  const Title = getAppTitle();
  if (AppTitle) {
    Title.innerText = AppTitle;
  }
};

useEffect(() => {
  handlefavicon();
  handleAppTitle();
  //eslint-disable-next-line
}, [logo]);

function captchaVisibility(){
  document.getElementsByClassName("grecaptcha-badge")[0].style.visibility = 'visible';
}

const handleTrigger = (e,data) =>{
  e.preventDefault()
  let a= document.createElement('a');
  a.target= '_blank';
  if(data=== 'terms'){
    a.href= TermsConditions;
    a.click();
  }else if(data === 'privacy'){
     a.href= PrivacyPolicy
     a.click();
  }
  
  
}

const scrollToField = (fieldName) => {
  if (inputRef.current[fieldName]) {
     inputRef.current[fieldName].focus()
  }
}



  return (
   
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
           
          </Helmet>
          <div className="body" style={{fontFamily: fontFamily}}>
          <LanguageChange ConsumerAppId={id} PageName={PageName} Lang={LanguageCode ? LanguageCode : 'en'}/>
            <div>
              {loader ? (
                <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : null}
            </div>
            
            {/* <LanguageChange ConsumerAppId={id} PageName={PageName} /> */}
            <div
              className="main-bg update-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo ? logo : DefaultLogo} alt="Pepsi Micdrop Logo" />
                  </div>
                  <h2 className="snacks-title" style={{fontFamily: fontFamily, color: Header_Color}}>
                  {langData?.lbl_update_profile}
                  </h2>
                </div>
                <div className="wrapper-inner">
                { initValue ? 
                  <Formik
                    enableReinitialize
                    initialValues={initValue} 
                    onSubmit={onSubmit}
                    validationSchema={validitySchema}
                    innerRef={(formikActions) => {
                      formikActions ? setFormikErrors(formikActions.errors) : setFormikErrors({})
                    }
                    }
                  >
                    {(props) => (
                   
                    <Form className="mb-0" >
                        {
                          attributeConfigDetails ? attributeConfigDetails?.baseAttributes.filter(item => item.isSelect).map((e,i)=>{
                           if(e.key==="login"){
                            return (
                              null
                            )
                           }
                           else if(e.key==="email"){
                            return(
                              <div key={i}>
                              
                             </div>
                            )
                          }else if(e.key==="userAddress"){
                            return(

                              <Field key={i}>
                               {() =>
                                 <div className={
                                   props.touched[e.key] &&
                                   props.errors[e.key]
                                     ?  "update-sec form-group has-danger"
                                     : "update-sec form-group"
                                 } >
                                 
                                   <textarea type="text" className="custom-input" name={e.key}
                                   value={props.values[e.key] || ''}
                                   id={e.key}
                                   onChange={props.handleChange}
                                   onBlur={props.handleBlur}
                                   style={{ color: Input_color }}
                                   aria-label={e.key}
                                   disabled={IsSocial ?  (userData[e.mappedBy] && e?.modifyField ? true : false) : (e?.modifyField && e?.modifyField ? true : false)}
                                   ref={(el) => (inputRef.current[e.key] = el)}
                                   required 
                                   />
                                   <label className="custom-label" htmlFor={e.key}>
                                   { (langData["lbl_update_profile_"+e.key]? langData["lbl_update_profile_"+e.key] : e.title ) + (e?.required?"*":"")}
                                   </label>
                                   {props.touched[e.key] &&
                                     props.errors[e.key] ? (
                                       <div className="error-msg" aria-label={props.errors[e.key]}>
                                         {props.errors[e.key]}
                                       </div>
                                     ) : null}
                                 </div>  
                                }
                               </Field>
   
                            )
                           }else if(e.type === 'array' && e?.enumList && e?.oneOf && e?.enumList.length>0 && e?.oneOf.length>0){
                            return(
                              <Field key={"custom_"+i}>
                              {() =>
                                <div className={
                                  props.touched[e.key] &&
                                  props.errors[e.key]
                                    ? "update-sec form-group has-danger"
                                    : "update-sec form-group"
                                } >
                                  <select multiple={true} className="custom-input" name={e.key}
                                    value={props.values[e.key] || [""]}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    style={{ color: Input_color }}
                                    required 
                                    id={e.key}
                                    aria-label={e.key}
                                    disabled={IsSocial ?  (userData[e.mappedBy] && e?.modifyField ? true : false) : (e?.modifyField && e?.modifyField ? true : false)}
                                    ref={(el) => (inputRef.current[e.key] = el)}
                                  >
                                    <option value="" disabled></option>
                                    {
                                      e.oneOf.map((option,i)=>{
                                        return(
                                          <option key={option.constVal} value={option.constVal}>{option.title}</option>
                                        )
                                      })
                                    }
                                    
                                  </select>
                                  <label className='custom-label' htmlFor={e.key}>{e.title}</label>
                                  {/* <label className="custom-label">
                                  { (langData["lbl_create_account_"+e.key] ? langData["lbl_create_account_"+e.key]  : e.title ) +  (e?.required?"*":"") }
                                  </label> */}
                                  {props.touched[e.key] &&
                                    props.errors[e.key] ? (
                                      <div className="error-msg" aria-label={props.errors[e.key]}>
                                        {props.errors[e.key]}
                                      </div>
                                    ) : null}
                                </div>  
                              }
                            </Field>
                            )
                           }else if(e?.enumList && e?.oneOf && e?.enumList.length>0 && e?.oneOf.length>0){
                            return(
                              <Field key={"custom_"+i}>
                              {() =>
                                <div className={
                                  props.touched[e.key] &&
                                  props.errors[e.key]
                                    ? "update-sec form-group has-danger"
                                    : "update-sec form-group"
                                } >
                                  
                                  <select  className="custom-input" name={e.key}
                                    value={props.values[e.key] || ''}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    style={{ color: Input_color }}
                                    required
                                    id={e.key}
                                    aria-label={e.key}
                                    disabled={IsSocial ? (userData[e.mappedBy] && e?.modifyField ? true : false) :  (e?.modifyField && e?.modifyField ? true : false)}
                                    ref={(el) => (inputRef.current[e.key] = el)}
                                  >
                                     {
                                               (e?.dropDownURL !== '' ) ?
                                               e.key === 'comuna' && regionsObj   ?
                                              <>
                                               <option value="" disabled></option>
                                             {
                                             props.values['regions'] &&  Object.keys(regionsObj[e.key][props?.values?.regions])?.map( item => {
                                                 return (
                                                   <option
                                                     key={item}
                                                     value={regionsObj[e.key][props?.values?.regions][item]}
                                                   >
                                                     {item}
                                                   </option>
                                                 );
                                               })
                                             }
                                              </>
                                              :
                                              <>
                                              <option value="" disabled></option>
                                                {
                                                regionsObj[e.key] && Object.keys(regionsObj[e.key])?.map( item => {
                                                    return (
                                                      <option
                                                        key={item}
                                                        value={regionsObj[e.key][item]}
                                                      >
                                                        {item}
                                                      </option>
                                                    );
                                                  })
                                                }
                                              </> 
                                                : 
                                                <>
                                                <option value="" disabled></option>
                                              {e.oneOf.map((option, i) => {
                                                return (
                                                  <option
                                                    key={option.constVal}
                                                    value={option.constVal}
                                                    
                                                  >
                                                    {option.title}
                                                  </option>
                                                );
                                              })}
                                                </>
                                              }
                                  </select>
                                  {/* <label className="custom-label">
                                  { (langData["lbl_create_account_"+e.key] ? langData["lbl_create_account_"+e.key]  : e.title ) +  (e?.required?"*":"") }
                                  </label> */}
                                  <label className="custom-label" htmlFor={e.key}>
                                  { (langData["lbl_update_profile_"+e.key]? langData["lbl_update_profile_"+e.key] : e.title ) + (e?.required?"*":"")}
                                  </label>
                                  {props.touched[e.key] &&
                                    props.errors[e.key] ? (
                                      <div className="error-msg" aria-label={props.errors[e.key]}>
                                        {props.errors[e.key]}
                                      </div>
                                    ) : null}
                                </div>  
                              }
                            </Field>
                            )
                           }
                           else if(e?.type === "boolean"){
                            return(
                              <Field key={i}>
                               {() =>
                                 <div className={
                                   props.touched[e.key] &&
                                   props.errors[e.key]
                                     ? "update-sec form-group has-danger"
                                     : "update-sec form-group"
                                 } >
                                   {/* <label className="custom-checkbox" htmlFor={e.key}>
                                   { 
                                            e.mappedBy === 'optinB' ?
                                                  <p className="privacy-terms">
                                                      {langData?.lbl_update_profile_text_three}{' '}
                                                      {
                                                        PrivacyPolicy !== '' &&
                                                        <Link
                                                        to={{ pathname: PrivacyPolicy }}
                                                        target="_blank"
                                                        aria-label={langData?.lbl_update_profile_privacy_policy + ' opens in new nab'}
                                                        onClick={e?.modifyField && handleTrigger}
                                                      >
                                                        {langData?.lbl_update_profile_privacy_policy} <OpenInNewIcon />
                                                      </Link>
                                                      }
                                                      
                                                      {
                                                        TermsConditions !== '' &&
                                                        <>
                                                        {langData?.lbl_update_profile_and}
                                                          <Link
                                                            to={{ pathname: TermsConditions }}
                                                            target="_blank"
                                                            aria-label={langData?.lbl_update_profile_temrs_conditions + ' opens in new nab'}
                                                          >
                                                            {langData?.lbl_update_profile_temrs_conditions} <OpenInNewIcon />
                                                          </Link> 
                                                        </>
                                                      }
                                                      {langData?.lbl_update_profile_text_four}
                                                </p> 
                                                : langData['lbl_update_profile_' + e.key] ? langData['lbl_update_profile_' + e.key] : e.title
                                                 +
                                                (e?.required ? '*' : '')}
                                   <input 
                                      type="checkbox" 
                                      name={e.key}
                                      value={props.values[e.key] || false }
                                      checked={props.values[e.key] || false}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      style={{ color: Input_color }}
                                      required
                                      id={e.key}
                                      disabled={IsSocial ?  (userData[e.mappedBy] && e?.modifyField ? true : false) :  (e?.modifyField && e?.modifyField ? true : false)}
                                   />
                                    <span className="checkmark"></span>
                                   </label> */}

                                   <label className="custom-checkbox" htmlFor={e.key}>
                                              { 
                                               e.mappedBy === 'termsandconditions' || e.mappedBy === 'optinB' ?
                                                  <p className="privacy-terms">
                                                      {langData?.lbl_update_profile_text_three}{' '}
                                                      {
                                                        PrivacyPolicy !== '' &&
                                                        <Link
                                                        to={{ pathname: PrivacyPolicy }}
                                                        target="_blank"
                                                        aria-label={langData?.lbl_update_profile_privacy_policy + ' opens in new nab'}
                                                        onClick={(event) => {
                                                          if(e?.modifyField){
                                                            handleTrigger(event,"privacy")
                                                          }
    
                                                        }}
                                                      >
                                                        {langData?.lbl_update_profile_privacy_policy} <OpenInNewIcon />
                                                      </Link>
                                                      }
                                                      
                                                      {
                                                        TermsConditions !== '' &&
                                                        <>
                                                        {langData?.lbl_update_profile_and}
                                                          <Link
                                                            to={{ pathname: TermsConditions }}
                                                            target="_blank"
                                                            aria-label={langData?.lbl_update_profile_temrs_conditions + ' opens in new nab'}
                                                            onClick={(event) => {
                                                              if(e?.modifyField){
                                                                handleTrigger(event, "terms")
                                                              }
        
                                                            }}
                                                          >
                                                            {langData?.lbl_update_profile_temrs_conditions} <OpenInNewIcon />
                                                          </Link> 
                                                        </>
                                                      }
                                                      {langData?.lbl_update_profile_text_four}
                                                  </p> 
                                                : e.mappedBy === 'termsAndConditions_Seperate' ?
                                                <p className="privacy-terms">
                                                {
                                                  TermsConditions !== '' &&
                                                  <>
                                                  {langData?.lbl_update_profile_agree_our} 
                                                    <Link
                                                      to={{ pathname: TermsConditions }}
                                                      target="_blank"
                                                      aria-label={langData?.lbl_update_profile_temrs_conditions + ' opens in new nab'}
                                                      onClick={(event) => {
                                                        if(e?.modifyField){
                                                          handleTrigger(event,"terms")
                                                        }
  
                                                      }}

                                                    >
                                                      { ' ' + langData?.lbl_update_profile_temrs_conditions} <OpenInNewIcon />
                                                    </Link> 
                                                  </>
                                                }
                                                {langData?.lbl_update_profile_text_four}
                                            </p> : e.mappedBy === 'privacyPolicy_Seperate' ?
                                              <p className="privacy-terms">
                                                  {langData?.lbl_update_profile_text_three}{' '}
                                                  {
                                                    PrivacyPolicy !== '' &&
                                                    <Link
                                                    to={{ pathname: PrivacyPolicy }}
                                                    target="_blank"
                                                    aria-label={langData?.lbl_update_profile_privacy_policy+ ' opens in new nab'}
                                                    onClick={(event) => {
                                                      if(e?.modifyField){
                                                        handleTrigger(event, "privacy")
                                                      }

                                                    }}
                                                  >
                                                    {langData?.lbl_update_profile_privacy_policy} <OpenInNewIcon />
                                                  </Link>
                                                  }
                                              </p> 
                                                : langData['lbl_update_profile_' + e.key] ? langData['lbl_update_profile_' + e.key] : e.title
                                                +
                                                (e?.required ? '*' : '')}
                                              <input
                                                type="checkbox"
                                                name={e.key}
                                                id={e.key}
                                                value={props.values[e.key]}
                                                checked={props.values[e.key]}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                style={{ color: Input_color }}
                                                required
                                                aria-label={e.key}
                                                ref={(el) => (inputRef.current[e.key] = el)}
                                                disabled={IsSocial ?  (userData[e.mappedBy] && e?.modifyField ? true : false) :  (e?.modifyField && e?.modifyField ? true : false)}
                                              />
                                              <span className="checkmark"></span>
                                   </label>
                                   
                                   {props.touched[e.key] &&
                                     props.errors[e.key] ? (
                                       <div className="error-msg" aria-label={langData?.lbl_update_profile_general_required}>
                                         {langData?.lbl_update_profile_general_required}
                                       </div>
                                     ) : null}
                                 </div>  
                                }
                               </Field>
                            )
                          }else if(e?.type === "array"){
                            return(
                              <Field key={i}>
                               {() =>
                                 <div className={
                                   props.touched[e.key] &&
                                   props.errors[e.key]
                                     ? "update-sec form-group has-danger"
                                     : "update-sec form-group"
                                 } >
                                   <input type="text" className="custom-input" name={e.key}
                                   value={props.values[e.key] || ''}
                                   onChange={props.handleChange}
                                   onBlur={props.handleBlur}
                                   style={{ color: Input_color }}
                                   required
                                   id={e.key}
                                   aria-label={e.key}
                                   ref={(el) => (inputRef.current[e.key] = el)}
                                   disabled={IsSocial ?  (userData[e.mappedBy] && e?.modifyField ? true : false) :  (e?.modifyField && e?.modifyField ? true : false)}
                                   />
                                   <label className="custom-label" htmlFor={e.key}>
                                   { (langData["lbl_update_profile_"+e.key]? langData["lbl_update_profile_"+e.key] : e.title ) + (e?.required?"*":"")}
                                   </label>
                                   {props.touched[e.key] &&
                                     props.errors[e.key] ? (
                                       <div className="error-msg" aria-label={props.errors[e.key]}>
                                         {props.errors[e.key]}
                                       </div>
                                     ) : null}
                                 </div>  
                                }
                               </Field>
                               )

                           }else if(e?.key === 'dateOfBirth'){
                            return (
                              <FloatingLabelDatePicker
                                  name={e.key}
                                  label="Date Of Birth"
                                  keyname={e.key}
                                  langdata={langData}
                                  entiredata={e}
                                  entireprops={props}
                                  mindate={minDate}
                                  key={e.key}
                                  langname={pageName}
                                  langcode={LanguageCode}
                                  disabled={IsSocial ?  (userData[e.mappedBy] && e?.modifyField ? true : false) :  (e?.modifyField && e?.modifyField ? true : false)}
                              />
                            );
                          }
                           else {
                            return(
                              <Field key={i}>
                               {() =>
                                 <div className={
                                   props.touched[e.key] &&
                                   props.errors[e.key]
                                     ?  "update-sec form-group has-danger"
                                     : "update-sec form-group"
                                 } >
                                   <input type="text" className="custom-input" name={e.key}
                                   value={props.values[e.key] || '' }
                                   onChange={props.handleChange}
                                   onBlur={props.handleBlur}
                                   style={{ color: Input_color }}
                                   required
                                   id={e.key}
                                   aria-label={e.key}
                                   ref={(el) => (inputRef.current[e.key] = el)}
                                   disabled={IsSocial ? (userData[e.mappedBy] && e?.modifyField ? true : (socialLoginPhoneDisableField ? true : false))  :  (e?.modifyField && e?.modifyField ? true : false)}
                                   />
                                   <label className="custom-label" htmlFor={e.key}>
                                   { (langData["lbl_update_profile_"+e.key]? langData["lbl_update_profile_"+e.key] : e.title ) + (e?.required?"*":"")}
                                   </label>
                                   {props.touched[e.key] &&
                                     props.errors[e.key] ? (
                                       <div className="error-msg" aria-label={props.errors[e.key]}>
                                         {props.errors[e.key]}
                                       </div>
                                     ) : null}
                                 </div>  
                                }
                               </Field>
                               )
                           }
                            
                          }  
                          
                         
                          
                          ):
                          null
                        }
                        <div className="form-group">
                                <button className="btn btn-yellow btn-block "
                                    type="button"
                                    onClick={ () => {
                                      if (Object.keys(formikErrors).length === 0) {
                                        let unfilledList = attributeConfigDetails.baseAttributes
                                          .filter(item => item?.isSelect && item.required)
                                          .flatMap(item => {
                                            let unfilledFormikValues = Object.keys(props.values)
                                              .filter(formikValue => item.key === formikValue && props.values[formikValue] === undefined);
                                      
                                            return unfilledFormikValues;
                                          });
                                          scrollToField(unfilledList[0])
                                      }
                                        if (Object.keys(props.errors).length > 0) {
                                          // Find the first field with an error
                                          props.submitForm()
                                          let formikErrors1 = Object.keys(props.errors)
                                          let attributeOrder = attributeConfigDetails.baseAttributes.map(item => {
                                            if(item?.isSelect){
                                              for(let i=0;i<formikErrors1.length;i++){
                                                if(item.key === formikErrors1[i]){
                                                  return formikErrors1[i]
                                                }
                                              }
                                            }
                                            return null
                                          }).filter(item => item)
                                          const firstErrorField = attributeOrder[0];
                                          scrollToField(firstErrorField)
                                          // inputRef.current[firstErrorField].scrollIntoView({ behavior: 'smooth', block: 'center' });
                                        }else{
                                          console.log("ENTERED")
                                        props.handleSubmit()
                                      }
                                    }}
                                    aria-label={langData.lbl_update_profile_update_account}  
                                    style={{backgroundColor: SignIn_button_backgroundColor, color:Input_color}} 
                                >
                                     {langData.lbl_update_profile_update_account}  
                                </button>
                        </div>
                        <div className="form-group">
                          {
                            !social && 
                                <button className="btn btn-yellow-brd btn-block"
                                    style={{color: SignIn_button_color, background:SecondaryButtonColor}}
                                    type="button"
                                    onClick={BackHome}
                                    aria-label="Cancel"
                                >
                                    {langData?.lbl_update_profile_cancel}
                                </button>
                          }
                        </div>

                    </Form>
                    )}
                  </Formik>
                    
                  : null
                }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Updateprofile;
